import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/common/Href.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/ToastifyConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/TopLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/footer/ColorThemeSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/footer/LanguageSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/MobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/MoreDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/WalletIndicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EthereumProvider"] */ "/vercel/path0/lib/hooks/ethereum/EthereumProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/vercel/path0/lib/hooks/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorThemeProvider"] */ "/vercel/path0/lib/hooks/useColorTheme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/i18n/NextIntlClientProviderChild.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-lite-youtube-embed/dist/LiteYouTubeEmbed.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/index.css");
